import { app } from '@/plugins/app';
import Components from '@/components/index';

// Global
app.component('global-header', Components.Global.Header);
app.component('global-footer', Components.Global.Footer);
app.component('global-loading', Components.Global.Loading);
// Page 
app.component('page-header', Components.Page.Header);
app.component('info-card', Components.Page.InfoCard);
app.component('stage-total-card', Components.Page.StageTotalCard);
// Dashboard
app.component('dashboard-latest-jobs', Components.Dashboard.LatestJobs);
// Client
app.component('client-list-item', Components.Client.ListItem);
app.component('client-table-list', Components.Client.TableClient);
// Jobs
app.component('jobs-list-item', Components.Jobs.ListItem);
app.component('jobs-table-list', Components.Jobs.TableJobs);
app.component('jobs-stage-badge', Components.Jobs.StageBadge);
// Modals
app.component('modal-create-job', Components.Modals.Jobs.AddJob);
app.component('modal-view-job', Components.Modals.Jobs.ViewJob);
app.component('modal-schedule-job', Components.Modals.Jobs.ScheduleJob);
app.component('modal-delete-job', Components.Modals.Jobs.DeleteJob);
app.component('modal-filter-job', Components.Modals.Jobs.FilterJob);
app.component('modal-mark-complete', Components.Modals.Jobs.MarkAsComplete);
app.component('modal-create-client', Components.Modals.Client.AddClient);
app.component('modal-add-contact-to-client', Components.Modals.Client.AddContactToClient);
app.component('modal-edit-contact-to-client', Components.Modals.Client.EditContactToClient);
app.component('modal-edit-client', Components.Modals.Client.EditClient);
app.component('modal-delete-client',Components.Modals.Client.DeleteClient);
app.component('modal-create-user', Components.Modals.Users.AddUser);
app.component('modal-edit-user', Components.Modals.Users.EditUser);
app.component('modal-delete-user', Components.Modals.Users.DeleteUser);
app.component('modal-create-van', Components.Modals.Vans.AddVan);
app.component('modal-edit-van', Components.Modals.Vans.EditVan);
app.component('modal-delete-van', Components.Modals.Vans.DeleteVan);
app.component('modal-schedule-event', Components.Modals.Schedules.ScheduleEvent);
app.component('modal-view-event', Components.Modals.Schedules.ViewEvent);
app.component('modal-reschedule-event', Components.Modals.Schedules.RescheduleEvent);
app.component('modal-report-year', Components.Modals.Reports.ReportYear);
app.component('modal-report-range', Components.Modals.Reports.ReportRange);
// Users
app.component('user-list-item', Components.Users.ListItem);
app.component('user-table-list', Components.Users.TableUsers);
// Vans
app.component('van-table-list', Components.Vans.TableVan);
// Avatar
app.component('avatar-name', Components.Avatar.NameAvatar);

