<template>
    <div
      class="modal fade"
      id="kt_modal_report_year"
      tabindex="-1"
      aria-hidden="true"
    >
        <div class="modal-dialog modal-medium p-9">
            <div class="modal-content modal-rounded">
                <div class="modal-header py-7 d-flex justify-content-between">
                    <h2 class="m-0">Filter by year</h2>
                    <div
                        id="dismiss-modal-year"
                        class="btn btn-sm btn-icon btn-active-color-primary"
                        data-bs-dismiss="modal"
                        ref="closeModal"
                    >
                        <i class="ki-solid ki-cross fs-1"></i>
                    </div>
                </div>
                <div class="modal-body scroll-y m-5">
                    <div class="mb-5">
                        <label for="select-year" class="form-label">Year</label>
                        <select id="select-year" v-model="year" class="form-select" aria-label="Select year" placeholder="Please select year">
                            <option v-for="(year, index) in financialYearsList" :key="index" :value="year.value">{{ year.label }}</option>
                        </select>
                    </div>
                    <button class="btn btn-primary me-4" @click="submitForm()">Update report</button>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.dp__theme_dark {
 --dp-background-color: #1e1e2d;
 --dp-text-color: #bdbdc5;
 --dp-hover-color: #484848;
 --dp-hover-text-color: #ffffff;
 --dp-hover-icon-color: #959595;
 --dp-primary-color: #2caa3a;
 --dp-primary-text-color: #ffffff;
 --dp-secondary-color: #60677e;
 --dp-border-color: #323248;
 --dp-menu-border-color: #323248;
 --dp-border-color-hover: #aaaeb7;
 --dp-disabled-color: #737373;
 --dp-scroll-bar-background: #212121;
 --dp-scroll-bar-color: #484848;
 --dp-success-color: #00701a;
 --dp-success-color-disabled: #428f59;
 --dp-icon-color: #959595;
 --dp-danger-color: #e53935;
 --dp-highlight-color: rgba(0, 92, 178, 0.2);
 --dp-action-buttons-padding: 6px 17px;   
 --dp-input-padding: 10px 30px 10px 13px;
 --dp-font-family: 'Poppins';
 --dp-button-height: 50px;
 --dp-action-button-height: auto;
}
</style>
<script>
    export default {
        name: "ReportYear",
        data() {
            return {
                year: null,
                financialYearsList: []
            }
        },
        mounted() {
            this.$api.getFinancialYears().then((r) => {
                this.financialYearsList = r.data;
            });
        },
        methods: {
            updateChart() {
                let data = {
                    financialYear: this.year,
                    startDate: null,
                    endDate: null
                };
                this.$emit("updateChart", data);
            },
            submitForm() {
                this.updateChart();
                this.$refs.closeModal.click();
            }
        }
    }
</script>