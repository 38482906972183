export default class ChartStyle {
    bar;
    line;
    horizontal;
    constructor() {
        this.bar = {
            options: {
                theme: {
                    mode: 'dark', 
                },
                chart: {
                    background: 'transparent',
                    id: 'bar-chart',  
                    toolbar: {
                        show: false,
                    },
                },
                xaxis: {
                    categories: ["MAR"],
                    axisBorder: {
                        show: true,
                        color: '#3F4254',
                    }
                },
                yaxis: {
                    axisBorder: {
                        show: true,
                        color: '#3F4254',
                    }
                },
                legend: {
                    show: false
                },
                colors: ['#9723ff'],
                plotOptions: {
                    bar: {
                        horizontal: false,
                        borderRadius: 10,
                        rangeBarOverlap: false,
                        columnWidth: ['40%'],
                    },
                },
                grid: {
                    borderColor: "#3F4254",
                    xaxis: {
                        lines: {
                            show: false
                        }
                    },
                },
                dataLabels: {
                    enabled: false
                },
            },
            series: [{
                name: 'Quote value: ',
                data: [0]
            }]
        };
        this.line = {
            options: {
                theme: {
                    mode: 'dark', 
                },
                chart: {
                    background: 'transparent',
                    id: 'line-chart',  
                    toolbar: {
                        show: false,
                    },
                },
                xaxis: {
                    categories: ["VALUE"],
                    axisBorder: {
                        show: true,
                        color: '#3F4254',
                    }
                },
                yaxis: {
                    axisBorder: {
                        show: true,
                        color: '#3F4254',
                    }
                },
                legend: {
                    show: true
                },
                colors: ['#fefc01'],
                stroke: {
                    width: 8,
                    curve: 'smooth'
                },
                grid: {
                    borderColor: "#3F4254",
                    xaxis: {
                        lines: {
                            show: false
                        }
                    },
                },
                dataLabels: {
                    enabled: false
                },
            },
            series: [{
                name: 'VALUE',
                data: [0]
            }]
        };
        this.horizontal = {
            options: {
                theme: {
                    mode: 'dark', 
                },
                chart: {
                    background: 'transparent',
                    id: 'horizontal-chart',  
                    toolbar: {
                    show: false,
                    },
                },
                xaxis: {
                    categories: ["VALUE"],
                    axisBorder: {
                        show: true,
                        color: '#3F4254',
                    }
                },
                yaxis: {
                    axisBorder: {
                        show: true,
                        color: '#3F4254',
                    }
                },
                legend: {
                    show: true
                },
                colors: ['#01fd01', "#b7b7b7"],
                plotOptions: {
                    bar: {
                        horizontal: true,
                        borderRadius: 6,
                        rangeBarOverlap: false,
                        barHeight: ['40%'],
                    },
                },
                grid: {
                    borderColor: "#3F4254",
                    xaxis: {
                        lines: {
                            show: false
                        }
                    },
                },
                dataLabels: {
                    enabled: false
                },
            },
            series: [{
                name: 'VALUE',
                data: [0]
            }]
        };
    }
}