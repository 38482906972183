<template>
    <div
      class="modal fade"
      id="kt_modal_reschedule_event"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl p-9" v-if="task != null">
        <div class="modal-content modal-rounded">
          <div class="modal-header py-7 d-flex justify-content-between">
            <h2 class="m-0">Reschedule {{getEventTypeDisplay(task.eventType)}}</h2>
            <div
              id="dismiss-modal"
              class="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
              ref="closeModal"
              @click="cancelForm()"
            >
              <i class="ki-solid ki-cross fs-1"></i>
            </div>
          </div>
          <div class="modal-body m-5">

            <div class="row mb-6">
              
              <div class="col-12 d-flex mb-4" v-if="task.eventType != 3">
                <div class="symbol symbol-30px w-30px h-30px me-4 symbol-circle bg-info">
                  <div class="symbol-label bg-info">
                    <i class="fas fa-solid fa-info-circle fs-4 text-dark"></i>
                  </div>
                </div>
                <p class="m-0 fs-6"><span class="text-muted d-block">{{getEventTypeDisplay(task.eventType)}} Title</span> {{task.title}}</p>
              </div>
            </div>
              
            <div v-if="task.eventType != null">    
                <div class="mb-10">
                    <label for="description" class="form-label">Description</label>
                    <div class="position-relative">
                      <textarea v-model="form.description" id="description" class="form-control" placeholder="eg: Installation of new system"/>
                    </div>
                </div>               
                <div class="mb-10" v-if="task.eventType > 2 && task.eventType != 0">
                    <label for="userProfileId" class="required form-label">Assign to</label>
                    <div class="position-relative">
                      <span class="text-danger" v-if="v$.form.userProfileId.$error">Please select a person</span>
                      <select v-model="form.userProfileId" id="userProfileId" name="userProfileId" class="form-select" :class="{'is-invalid' : v$.form.userProfileId.$error}">
                        <option v-for="(user, index) in availableUsers" :key="index" :value="user.value">{{ user.label }}</option>
                      </select>
                    </div>
                </div>
                <div class="mb-10" v-if="task.eventType < 3">
                    <label for="userProfileId" class="required form-label">Who is this task for?</label>
                    <div>
                    <span class="text-danger" v-if="v$.form.usersAssigned.$error">Please select at least one person</span>
                    </div>
                    <div class="form-check form-check-custom form-check-lg mb-3" v-for="(user, index) in availableUsers" :key="index">
                        <input class="form-check-input" :aria-label="index" :value="user.value" type="checkbox" :class="{'is-invalid' : v$.form.usersAssigned.$error}" v-model="form.usersAssigned" :id="'user'+index" />
                        <label class="form-check-label text-dark" :for="'user'+index">
                            {{ user.label }}
                        </label>
                    </div>
                </div>
                <div class="mb-10">
                    <label for="startDateTime" class="required form-label">Start date/time</label>
                    <div class="position-relative">
                      <span class="text-danger" v-if="v$.form.startDateTime.$error">Please provide a start date and optional time</span>  
                      <VueDatePicker :dark="true" :is-24="true" time-picker-inline v-model="form.startDateTime" format="dd/MM/yyyy HH:mm" position="left" :start-time="startTime" @update:model-value="convertStartDate" >
                        <template #dp-input="{ value }">
                            <input type="text" :value="value" id="startDateTime" class="form-control" :class="{'is-invalid' : v$.form.startDateTime.$error}" placeholder=""/>
                        </template>
                      </VueDatePicker>
                    </div>
                </div>
                <div class="mb-10">
                    <label for="endDateTime" class="form-label">End date/time</label>    
                    <VueDatePicker :dark="true" :is-24="true" time-picker-inline v-model="form.endDateTime" format="dd/MM/yyyy HH:mm" position="left" :start-time="startTime" @update:model-value="convertEndDate" >
                      <template #dp-input="{ value }">
                          <input type="text" :value="value" id="endDateTime" class="form-control" placeholder=""/>
                      </template>
                    </VueDatePicker>
                </div>
                <div class="mb-10" v-if="task.eventType == 2 || task.eventType == 1">
                    <label for="vanId" class="form-label" :class="{'required': form.eventType == 2, 'is-invalid' : v$.form.vanId.$error}">Select a van for this task</label>
                    <div class="position-relative">
                      <select v-model="form.vanId" id="vanId" name="vanId" class="form-select" :class="{'is-invalid' : v$.form.vanId.$error}">
                        <option v-for="(van, index) in availableVans" :key="index" :value="van.value">{{ van.label }}</option>
                      </select>
                    </div>
                </div>
            </div>
            
            <button class="btn btn-primary me-4" @click="submitForm()">Submit</button>
            <button class="btn btn-light" @click="cancelForm()">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </template>

  <style>
  .dp__theme_dark {
   --dp-background-color: #1e1e2d;
   --dp-text-color: #bdbdc5;
   --dp-hover-color: #484848;
   --dp-hover-text-color: #ffffff;
   --dp-hover-icon-color: #959595;
   --dp-primary-color: #2caa3a;
   --dp-primary-text-color: #ffffff;
   --dp-secondary-color: #60677e;
   --dp-border-color: #323248;
   --dp-menu-border-color: #323248;
   --dp-border-color-hover: #aaaeb7;
   --dp-disabled-color: #737373;
   --dp-scroll-bar-background: #212121;
   --dp-scroll-bar-color: #484848;
   --dp-success-color: #00701a;
   --dp-success-color-disabled: #428f59;
   --dp-icon-color: #959595;
   --dp-danger-color: #e53935;
   --dp-highlight-color: rgba(0, 92, 178, 0.2);
   --dp-action-buttons-padding: 6px 17px;   
   --dp-input-padding: 10px 30px 10px 13px;
   --dp-font-family: 'Poppins';
   --dp-button-height: 50px;
   --dp-action-button-height: auto;
}
  </style>

  <script lang="js">
  import moment from "moment";
  import useVuelidate from "@vuelidate/core";
  import { required, requiredIf } from "@vuelidate/validators";
  import VueDatePicker from '@vuepic/vue-datepicker';
  import '@vuepic/vue-datepicker/dist/main.css'
  
  export default {
    name: 'RescheduleEvent',
    props: ['task', 'user'],
    components: {
      VueDatePicker
    },
    data() { 
      return {
        v$ : useVuelidate(),
        form: {
          description: '',
          startDateTime: '',
          endDateTime: '',
          complianceTaskId: null,
          vanId: null,
          userProfileId: null,
          usersAssigned: []
        },
        eventId: null,
        availableVans: [],
        availableUsers: []
      }
    },
    validations() {
      return {
        form: {
          userProfileId : { requiredIf: requiredIf(() => {
            return this.task.eventType > 2 && this.task.eventType != null;
          })},
          usersAssigned : { requiredIf: requiredIf(() => {
            return this.task.eventType < 3 && this.task.eventType != null;
          })},
          complianceTaskId : { requiredIf: requiredIf(() => {
            return this.task.eventType === 2 && this.task.eventType != null;
          })},
          vanId : { requiredIf: requiredIf(() => {
            return this.task.eventType === 2 && this.task.eventType != null;
          })},
          startDateTime: { required }
        }
      }
    },
    created() {
      this.initData();
    },
    watch: {
      task: function (newVal, oldVal) {
        if (newVal != oldVal) {
          this.eventId = this.task.id;
          this.form = this.task;
        }
      }
    },
    methods: {
      initData() {
        this.$api.getUsersToAssign().then((r) => {
          this.availableUsers = r.data;
        });
        this.$api.getVanSelectList().then((r) => {
            this.availableVans = r.data;
        });
      },
      convertStartDate(startDate) {
        this.form.startDateTime = moment(startDate).format('yyyy-MM-DD HH:mm');
      },
      convertEndDate(endDate) {
        this.form.endDateTime = moment(endDate).format('yyyy-MM-DD HH:mm');
      },
      getEventTypeDisplay(eventType) {
        switch(eventType) {
          case 1:
            return 'Meeting';
          case 2:
            return 'Task';
          case 3:
            return 'Holiday';
          case 4:
            return 'Authorised Absence';
        }
      },
      async submitForm() {
        let startDate = this.form.startDateTime;
        await this.v$.$validate();
        if(!this.v$.$error) {
          this.$api.editScheduledEvent(this.eventId, this.form).then(() => {
            this.cancelForm();
            this.v$.$reset();
            this.$parent.initData(moment(startDate).format('yyyy-MM-DD'));
          });
        }
      },
      async clearForm() {
        this.form.description = '';
        this.form.startDateTime = '';
        this.form.endDateTime = '';
        this.form.usersAssigned = [];
        this.form.vanId = null;
        this.form.userProfileId = null;
        this.form.complianceTaskId = null;
        this.v$.$reset();
      },
      cancelForm() {
        this.$refs.closeModal.click();
        this.clearForm();
        this.$parent.$forceUpdate();
      }
    }
  };
  </script>
  