<template>
    <div class="app-wrapper flex-column flex-row-fluid">
      <div class="app-main flex-column flex-row-fluid">
        <div class="d-flex flex-column flex-column-fluid">
          <page-header :title="'Reports'" :links="links">
              <div class="dropdown">
                <button class="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Filter chart
                </button>
                <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#kt_modal_report_range">By date range</a></li>
                    <li><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#kt_modal_report_year">By Year</a></li>
                </ul>
            </div>
          </page-header>
          
          <div class="app-container container-xxl">
            <div class="row g-6 g-xl-9">
              <div class="col-12">
                <h2 class="mb-0">{{ range }}</h2>
              </div>
              <div class="col-12 col-lg-7">
                <div class="card mb-6 mb-lg-9">
                  <div class="card-header border-0 pt-5 justify-content-end align-items-end ">
                    <h3 class="card-title align-items-end flex-column">
                      <span class="card-label fw-bold fs-3 mb-1">Number of enquiries in each month</span>
                    </h3>
                  </div>
                  <div class="card-body py-0">                    
                    <div class="d-flex justify-content-center align-items-center w-100 h-300px" v-if="!loaded">
                        <div class="spinner-border" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    <apexchart ref="line" v-if="loaded" width="100%" type="line" :options="chart.lines.options" :series="chart.lines.series"></apexchart>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header border-0 pt-5 justify-content-end align-items-end">
                    <h3 class="card-title flex-column">
                      <span class="card-label fw-bold fs-3 mb-1">Total quote values in each month</span>
                    </h3>
                  </div>
                  <div class="card-body pt-0 pb-1 px-3 px-md-3">
                      <div class="d-flex justify-content-center align-items-center w-100 h-300px" v-if="!loaded">
                        <div class="spinner-border" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    <apexchart ref="bar" v-if="loaded" width="100%" type="bar" :options="chart.bar.options" :series="chart.bar.series"></apexchart>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-5">
                <div class="card h-800px h-lg-100">
                  <div class="card-header border-0 pt-5  justify-content-end align-items-end ">
                    <h3 class="card-title flex-column">
                      <span class="card-label fw-bold fs-3 mb-1 d-block text-end">Comparison of live and lost jobs</span>
                    </h3>
                  </div>
                  <div class="card-body pt-0">
                    <div class="d-flex justify-content-center align-items-center w-100 h-100" v-if="!loaded">
                        <div class="spinner-border" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    <apexchart ref="horizontal" v-if="loaded" height="100%" width="100%" type="bar" :options="chart.horizontal.options" :series="chart.horizontal.series"></apexchart>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <modal-report-year @updateChart="updateChart"/>
      <modal-report-range @updateChart="updateChart"/>
    </div>
  </template>
  
  <script>
  import moment from 'moment';
  export default {
    name: "Reports",
    data() {
      return {
        links: [
          {
            url: "/",
            title: "Dashboard",
          },
        ],
        loaded: false,
        range: "",
        model : {
          financialYear: 2024,
          startDate: null,
          endDate: null
        },
        chart: {
          bar: this.$chart.bar,
          lines: this.$chart.line,
          horizontal: this.$chart.horizontal,
        }
      };
    },
    methods: {
      updateChart(data) {
        let startDate = moment(data.startDate).format("DD/MM/YYYY");
        let endDate = moment(data.endDate).format("DD/MM/YYYY");
        let title = data.financialYear != null ? data.financialYear : startDate + ' to ' + endDate;
        this.range = "Current viewing: " + title;
        this.$api.getReportData(data).then((r) => {
          // Set enquiry chart data
          this.chart.lines.options.xaxis.categories.splice(0, this.chart.lines.options.xaxis.categories.length);
          r.data.enquiryChart.labels.forEach((label) => {
            this.chart.lines.options.xaxis.categories.push(label);
          });
          this.chart.lines.series = r.data.enquiryChart.series;
          // Set quote chart data
          this.chart.bar.options.xaxis.categories.splice(0, this.chart.bar.options.xaxis.categories.length);
          r.data.quoteChart.labels.forEach((label) => {
            this.chart.bar.options.xaxis.categories.push(label);
          });
          this.chart.bar.series = r.data.quoteChart.series;
          // Set Live/Lost chart data
          this.chart.horizontal.options.xaxis.categories.splice(0, this.chart.horizontal.options.xaxis.categories.length);
          r.data.liveLostChart.labels.forEach((label) => {
            this.chart.horizontal.options.xaxis.categories.push(label);
          });
          this.chart.horizontal.series = r.data.liveLostChart.series;
          // Once data is all loaded then hide spinner
          this.loaded = true;
        });
      }
    },
    mounted() {
      this.updateChart(this.model);
    },
  };
  </script>