<template lang="English">
    <div class="d-flex flex-column flex-root" id="kt_app_root">
        <!--begin::Page bg image-->

        <!--end::Page bg image-->

        <!--begin::Authentication - Sign-in -->
        <div class="d-flex flex-column flex-lg-row flex-column-fluid">
            <!--begin::Aside-->
            <div class="d-flex flex-lg-row-fluid">
                <!--begin::Content-->
                <div class="d-flex flex-column flex-center pb-0 pb-lg-10 p-10 w-100">
                    <img
                        alt="Logo"
                        src="/assets/media/logos/clev-logo.png"
                        class="h-50px h-lg-80px"
                    />
                </div>
                <!--end::Content-->
            </div>
            <!--begin::Aside-->

            <!--begin::Body-->
            <div class="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12">
                <!--begin::Wrapper-->
                <div class="bg-body d-flex flex-column flex-center rounded-4 w-md-600px p-10">
                    <!--begin::Content-->
                    <div class="d-flex flex-center flex-column align-items-stretch h-lg-100 w-md-400px">
                        <!--begin::Wrapper-->
                        <div class="d-flex flex-center flex-column flex-column-fluid pb-15 pb-lg-20">

                            <!--begin::Form-->
                            <form class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate" id="kt_sign_in_form" @submit.prevent="login()">
                                <!--begin::Heading-->
                                <div class="text-center mb-11">
                                    <!--begin::Title-->
                                    <h1 class="text-dark fw-bolder mb-3">
                                        Sign In
                                    </h1>
                                    <!--end::Title-->

                                </div>
                                <!--begin::Heading-->

                                <div class="alert alert-danger mb-4" v-if="usernameError">
                                    Please ensure that your email address and password are correct
                                </div>

                                <!--begin::Input group--->
                                <div class="fv-row mb-8 fv-plugins-icon-container"> 
                                    <span class="text-danger" v-if="v$.form.emailAddress.$error && v$.form.emailAddress.required.$invalid">Please enter your email address</span>
                                    <span class="text-danger" v-if="v$.form.emailAddress.$error && v$.form.emailAddress.email.$invalid">Please enter an valid email address</span>
                                    <!--begin::Email-->
                                    <input v-model="form.emailAddress" :class="{'is-invalid' : v$.form.emailAddress.$error}" type="text" placeholder="Email" name="email" autocomplete="off" class="form-control bg-transparent">
                                    <!--end::Email-->
                                    <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                                </div>

                                <!--end::Input group--->
                                <div class="fv-row mb-3 fv-plugins-icon-container">
                                    <span class="text-danger" v-if="v$.form.password.$error">Please enter your password</span>
                                    <!--begin::Password-->
                                    <input v-model="form.password" :class="{'is-invalid' : v$.form.password.$error}" type="password" placeholder="Password" name="password" autocomplete="off" class="form-control bg-transparent">
                                    <!--end::Password-->
                                    <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                                </div>
                                <!--end::Input group--->

                                <!--begin::Wrapper-->
                                <div class="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
                                    <!--begin::Link-->
                                    <a href="/forgot-password" class="link-primary">
                                        Forgot Password ?
                                    </a>
                                    <!--end::Link-->
                                </div>
                                <!--end::Wrapper-->

                                <!--begin::Submit button-->
                                <div class="d-grid mb-10">
                                    <button id="kt_sign_in_submit" class="btn btn-primary" :disabled="signingIn">

                                        <!--begin::Indicator label-->
                                        <span class="indicator-label" v-if="!signingIn">
                                            Sign In</span>
                                        <!--end::Indicator label-->

                                        <!--begin::Indicator progress-->
                                        <span class="indicator-label" v-if="signingIn">
                                            Please wait... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span>
                                        <!--end::Indicator progress--> 
                                    </button>
                                </div>
                                <!--end::Submit button-->
                            </form>
                            <!--end::Form-->

                        </div>
                        <!--end::Wrapper-->

                    </div>
                    <!--end::Content-->
                </div>
                <!--end::Wrapper-->
            </div>
            <!--end::Body-->
        </div>
        <!--end::Authentication - Sign-in-->
    </div>
</template>

<script>
//import { useRoute } from "vue-router";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
export default {
    name: "Login",
    data() {
        return {   
            v$ : useVuelidate(),
            signingIn: false,
            usernameError: false,
            form: {
                emailAddress: '',
                password: '',
            }
        };
    },
    validations() {
        return {
            form: {
                password: { required }, 
                emailAddress: { email, required }
            } 
        }
    },
    created() {
        document.body.removeAttribute('class');
        document.body.setAttribute('data-page', 'login');
        document.body.setAttribute('class', 'app-blank bgi-size-cover bgi-attachment-fixed bgi-position-center');
    },
    methods: {
        async login() {
            this.usernameError = false;
            this.signingIn = true;
            
            await this.v$.$validate();
            if(!this.v$.$error) {
                await this.$auth.signIn(this.form.emailAddress, this.form.password);
                if (this.$auth.isAuthenticated) {
                    setTimeout(() =>{
                        window.location.href = '/';
                        this.signingIn = false;
                        this.usernameError = false;
                    }, 3000);
                } else {
                    this.usernameError = true;
                    this.signingIn = false;
                }
            } else {
                this.signingIn = false;
                this.usernameError = false;
            }
        }
    },
};
</script>
