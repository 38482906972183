import { app } from "@/plugins/app";
import "@/plugins/components";
import router from "@/plugins/router";
import Emitter from "tiny-emitter";
import ApiService from "./services/api-service";
import { AuthService } from "@/plugins/auth";
import ChartStyle from "./json/chart";
import VueApexCharts from 'vue3-apexcharts'

fetch('/config', {
    method: 'GET',
    headers: {
        'Accept': 'application/json',
    },
}) 
  .then(r => r.json())
  .then((s) => {
    var _apiservice = new ApiService(s.Api_Base_Url);
    var _chart = new ChartStyle();
    app.use(new AuthService(), s);
    app.use(VueApexCharts);
    app.config.globalProperties.$emitter = new Emitter();
    app.config.globalProperties.$api = _apiservice;
    app.config.globalProperties.$chart = _chart;
    //console.log(app.config.globalProperties.$api);
    app.use(router).mount("#app");
 });
